document.addEventListener('DOMContentLoaded',function(){
    function dotContainer(gap=0) {
        function dotIntersection(items) {
            const observer = new IntersectionObserver(entries=> {
                entries.forEach(entry => {
                entry.target.classList.toggle("show", entry.isIntersecting);
                showItems = document.querySelector('.dot-wrapper').querySelectorAll('.show');

                })
           
            },{threshold:.9,})
    
            items.forEach(item => {
             observer.observe(item);
            })
        }
        const dottedSliders = document.querySelectorAll('.dotted');
        dottedSliders.forEach(slider=>{
            const childrens =Array.from(slider.children).filter(child => child !=="");
    
            const childCount = childrens.length;
            let childwidth = childrens[0].clientWidth;
            let totalChildWidh = childwidth + gap;
            let dotWrapper = document.createElement('div');
            dotWrapper.classList.add('dot-wrapper');
            dotWrapper.classList.add('fadePC');
            dotWrapper.classList.add('fadeMobile');
            dotWrapper.classList.add('up');
            for(let i = 0 ; i < childCount ; i++) {
                let dot = document.createElement('div');
                dot.classList.add('dot');
                dotWrapper.appendChild(dot)
            }
            dotIntersection(dotWrapper.querySelectorAll('.dot'));
            slider.closest('.dot-container').appendChild(dotWrapper);
            dotWrapper.querySelector('.dot').classList.add('active');


            // if(slider.closest('.overflow-wrapper')) {
            //     slider = slider.closest('.overflow-wrapper');
            // }
            // else {
                if(window.innerWidth < 768) {
                    const margin = Math.max(12 ,(window.innerWidth*0.5) - (960 / 2));
                const sliderObserver = new IntersectionObserver(entries=>{
                    entries.forEach(entry=>{
                            entry.target.classList.toggle('active',entry.isIntersecting);
                    })
                },{
                    threshold:.8,
                    rootMargin:`0px -${margin}px`,
                    }
                );
                childrens.forEach(child=>{
                    child.classList.add('slider-item');
                    sliderObserver.observe(child);
                })
                } 
            // }
            slider.addEventListener('scroll', function(){
                let dots = Array.from(dotWrapper.children).filter(child => child.tagName === 'DIV')
                let scrollAmount = slider.scrollLeft;
                current = Math.round(scrollAmount / totalChildWidh);
                if(!dots[current].classList.contains('active')) {
                    dots.forEach(child=>{
                        child.classList.remove('active');
                    })
                    dots[current].classList.add('active');
                    let showItems = dotWrapper.querySelectorAll('.show');
                    let index = Array.from(showItems).findIndex(el => el.classList.contains('active'));
                    if(index === showItems.length - 1) {
                        dotWrapper.scrollLeft += 22
                    }
                    else if(index == 0) {
                        dotWrapper.scrollLeft -= 22
                    }
                }
                else {
                    return
                }
            });
            if(window.innerWidth < 920) {
                dotWrapper.addEventListener('touchstart', function(e) {
                    e.preventDefault();
                })
            }
        })
        
    }
    let gap =0;
    (window.innerWidth>475)?gap = 20:gap = 16;
    dotContainer(gap);
    const greetAnimation = require('./modules/greetAnimation');
    const politics = require('./modules/politics');
    const layoutFunc = require('./modules/layout');
    const typing = require('./modules/typingAnimation');
    const burger = require('./modules/hamburger');
    const formAjax = require('./modules/formAjax');
    const aboutAnimation = require('./modules/aboutAnimation');
    const fadeInMobile = require('./modules/fadeInMobile');
    // const calcQuestionItem = require('./modules/questions');
    const getMask = require('./modules/mask');
    ////////////////
    ///
    ////
    // greetAnimation(800);
    formAjax();
    layoutFunc();
    typing();
    burger();
    
    // calcQuestionItem();
    getMask();
    politics();
    if(window.screen.width> 475) {
        aboutAnimation();
    }
    if (window.screen.width<475) {
        fadeInMobile();
    }
    if(window.screen.width >920) {
        if(document.querySelector('.shine')) {
            let shines = document.querySelectorAll('.shine');
            shines.forEach(shine=>{
                shine.addEventListener('mousemove', function(e){
                    const rect = shine.getBoundingClientRect();
                    const offsetX = e.clientX - rect.left; // Вычисляем offsetX относительно самого shine
                    const offsetY = e.clientY - rect.top; // Вычисляем offsetY относительно самого shine
                    shine.style.setProperty('--left', offsetX + 'px');
                    shine.style.setProperty('--top', offsetY + 'px');
                })
            })
        }
    }
    // if (window.screen.width < 920) {
    //     if (document.querySelector('.shine')) {
    //         let shines = document.querySelectorAll('.shine');
    //         shines.forEach(shine => {
    //             shine.addEventListener('touchmove', function(e) {
    //                 // Остановка дальнейшего распространения события
    //                 e.preventDefault();
    
    //                 // Получаем координаты первого касания
    //                 const touch = e.touches[0];
    //                 const rect = shine.getBoundingClientRect();
    //                 const offsetX = touch.clientX - rect.left; // Вычисляем offsetX относительно самого shine
    //                 const offsetY = touch.clientY - rect.top; // Вычисляем offsetY относительно самого shine
    
    //                 // Устанавливаем переменные
    //                 shine.style.setProperty('--left', offsetX + 'px');
    //                 shine.style.setProperty('--top', offsetY + 'px');
    //             });
    //         });
    //     }
    // }
        
});




  